import { Button, Modal, Result, Image, List, Typography } from "antd";
import React from "react";

const { Title, Text } = Typography;

const ConfirmModal = ({ materials, data, modalStates, modalActions }) => {
  // Helper function to get material details by ID
  const getMaterialDetails = (obj) => {
    return materials.parsedData.find(
      (material) => material.value === obj.material
    );
  };

  return (
    <>
      <Modal
        centered
        title={<Title level={4}>Confirmación</Title>}
        open={modalStates.isConfirmationModalVisible}
        onCancel={() => modalActions.setIsConfirmationModalVisible(false)}
        footer={[
          <Button key="reject" onClick={modalActions.handleReject}>
            Rechazar
          </Button>,
          <Button
            key="accept"
            type="primary"
            onClick={modalActions.handleAccept}
          >
            Aceptar
          </Button>,
        ]}
      >
        <div style={{ marginBottom: "16px" }}>
          <Text strong>Fecha esperada de recepción:</Text>{" "}
          <Text>
            {data?.date && !isNaN(new Date(data.date))
              ? new Date(data.date).toLocaleDateString()
              : "Fecha no válida"}
          </Text>
        </div>

        {data?.files && data?.files.length > 0 && (
          <div style={{ textAlign: "center", marginBottom: "16px" }}>
            <Image
              width={200}
              src={data?.files[0].url}
              alt="Imagen enviada"
              style={{ borderRadius: "8px" }}
            />
          </div>
        )}

        <div style={{ marginBottom: "16px" }}>
          <Text strong>Información adicional:</Text>{" "}
          <Text>{data?.additional_info || "No proporcionada"}</Text>
        </div>

        {data?.material_list && data?.material_list.length > 0 && (
          <List
            style={{ height: "300px", overflowY: "auto" }}
            header={<Title level={5}>Materiales</Title>}
            bordered
            dataSource={data?.material_list}
            renderItem={(id) => {
              const material = getMaterialDetails(id);
              return (
                <List.Item>
                  {material ? (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Image
                        width={50}
                        src={material.image}
                        alt={`Material ${id}`}
                        style={{ borderRadius: "4px" }}
                      />
                      <Text style={{ marginLeft: "10px" }}>
                        {material.text}
                      </Text>
                    </div>
                  ) : (
                    <Text>Material ID: {id} (No encontrado)</Text>
                  )}
                </List.Item>
              );
            }}
          />
        )}
      </Modal>

      <Modal
        centered
        open={modalStates.resultModal.visible}
        footer={null}
        onCancel={() =>
          modalActions.setResultModal({ visible: false, status: "", title: "" })
        }
      >
        <Result
          status={modalStates.resultModal.status}
          title={modalStates.resultModal.title}
        />
      </Modal>
    </>
  );
};

export default ConfirmModal;
