import React from "react";
import "./home.css";
import { ReactComponent as ArrowBackIcon } from "assets/icons/svg/arrow-back.svg";
import { Avatar, Skeleton, Popover, Badge, Switch, Space } from "antd-mobile";
import {
  AddOutline,
  ExclamationTriangleOutline,
  FileOutline,
  UnorderedListOutline,
} from "antd-mobile-icons";
import {
  Dropdown,
  Tag,
  Progress,
  Card,
  FloatButton,
  Button,
  Drawer,
} from "antd";
import {
  configClass,
  configTitleIcon,
} from "components/molecules/container/Container";
import { getStateColor, moneyFormat } from "../services/services";
import { auxiliarPhasesArray, PHASE_ICONS } from "../components/Tabs";
import Navigation from "../components/Navigation";
import { getPercentPhases } from "views/services-provider/ps-new-entry/phases";
import ProgressBar from "components/atoms/progess-bar/ProgressBar";
import WALLET_ICON from "assets/icons/svg/icons8-billetera.svg";
import PAY_ICON from "assets/icons/svg/pay.svg";
import PROGRESSION_ICON from "assets/icons/svg/progression.svg";
import { ReactComponent as HouseIcon } from "assets/icons/svg/CarbonHome.svg";
import Services from "../services/Services.jsx";
import NavBarController from "components/Navbar/NavBarController";
import WORKPLAN_ICON from "assets/icons/svg/work-plan.svg";
import { ALL_SERVICES_ADDED } from "router/routes";
import { CheckCircleOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import useReduxState from "custom-hooks/useReduxState";
import {
  setChatPhaseSelected,
  setPhasesStatus,
} from "reducers/bitacle.reducer";
import SimpleUnexpectedForm from "../components/SimpleUnexpectedForm/SimpleUnexpectedForm";
import { updateService } from "services-controller/http.cleinte.controller";

const NewHome = ({
  servicesInCourse,
  service,
  form_user,
  history,
  handleChangeService,
  phasesOptions,
  setChat,
  loading,
  actions,
  states,
  data,
  servicesList,
  navigatorService,
  isInPsView = false,
  haveServiceOperation,
}) => {
  const [, dispatch] = useReduxState(
    { setChatPhaseSelected, setPhasesStatus },
    "bitacle"
  );
  const [serviceIndex, setServiceIndex] = React.useState(0);
  const [unExpectedFormActive, setUnExpectedFormActive] = React.useState(false);
  const [totalValue, setTotalValue] = React.useState(null);
  const [valueToPay, setValueToPay] = React.useState(null);

  let [phases, totalPercent] = getPercentPhases(
    service?.state.globalState,
    service?.bitacle_v,
    service?.state.stage
  );

  const handleMarkAsProperty = async (service) => {
    await updateService(service?.unique_key, {
      ...service,
      metadata: {
        ...service.metadata,
        property: {
          ...service.metadata.property,
          has_property: true,
        },
      },
    });
  };

  const isPhaseActive = (phase, stage) => {
    const currentServicePhaseCode = (() => {
      switch (stage) {
        case 9:
          return 0;
        case 10:
          return 1;
        case 5:
          return 2;
        default:
          return null;
      }
    })();

    if (currentServicePhaseCode != phase.id && phase.phase.code != 3) {
      return false; // is inactive
    }
    return true;
  };
  const openSimpleUnexpectedForm = () => {
    setUnExpectedFormActive(true);
  };
  const visiblePhases = (stage) => {
    switch (stage) {
      case 12:
        return ["0", "1", "2", "3", 0, 1, 2, 3];
      case 2:
        return ["3", 3];
      case 9:
        return ["0", "3", 0, 3];
      case 10:
        return ["0", "1", "3", 0, 1, 3];
      case 5:
        return ["0", "1", "2", "3", 0, 1, 2, 3];
      default:
        return [];
    }
  };

  React.useEffect(() => {
    if (data) {
      const total =
        data[serviceIndex]?.payment.paymentInfo.to_pay +
        data[serviceIndex]?.payment.paymentInfo.additionals -
        data[serviceIndex]?.payment.paymentInfo.discounts;
      setTotalValue(total);
      setValueToPay(total - data[serviceIndex]?.payment.paymentInfo.payed);
    }
  }, [data]);

  useEffect(() => {
    const phases = auxiliarPhasesArray.map((phase) => {
      const isVisible = visiblePhases(service?.state.stage).includes(phase.id);
      const phaseActive = isPhaseActive(phase, service?.state.stage);

      return {
        ...phase,
        active: phase.phase.code !== 3 ? phaseActive : true,
        visible: isVisible,
      };
    });

    dispatch.setPhasesStatus(phases);
  }, [service]); //eslint-disable-line

  const items = servicesInCourse?.map((service, index) => {
    if (!service) return null;

    return {
      key: index.toString(),
      label: (
        <div
          className="inside-menu"
          onClick={() => {
            setServiceIndex(index);
            handleChangeService(service);
          }}
        >
          <h3>{service.metadata.property.nickname.split("-")[1]}</h3>
          <p>{service.unique_key}</p>
          <Tag color={getStateColor(configClass(service.state.stage))}>
            {configTitleIcon(service.state.stage)}
          </Tag>
        </div>
      ),
    };
  });

  return service && service.bitacle_v === 2 ? (
    <main className="main-home">
      <section id="section-home-id" className="section-home">
        {loading ? (
          <article id="user-info">
            <Skeleton
              className="skeleton-avatar"
              animated
              style={{ "--size": "80px" }}
            />
            <Skeleton.Paragraph lineCount={2} animated />
          </article>
        ) : (
          <article id="user-info">
            <div>
              <h2>{service?.metadata.property.nickname}</h2>
              {/* <p>{service?.unique_key}</p> */}
              <br />
              {isInPsView && (
                <h3>
                  {" "}
                  📞{service?.metadata?.user.register_data.contact.phone}{" "}
                  {
                    service?.metadata?.user.register_data.contact
                      .email_associated
                  }
                </h3>
              )}
            </div>
            {!isInPsView ? (
              <Dropdown
                menu={{ items }}
                placement="bottomLeft"
                trigger={["click"]}
              >
                <UnorderedListOutline
                  className="icon-menu"
                  onClick={(e) => e.preventDefault()}
                  type="button"
                />
              </Dropdown>
            ) : (
              <Button
                onClick={() => history.push(ALL_SERVICES_ADDED)}
                className="go-back-btn"
                type="primary"
                shape="circle"
              >
                <ArrowBackIcon />
              </Button>
            )}
          </article>
        )}

        {loading ? (
          <article id="progress">
            <Skeleton animated style={{ height: "40px", width: "60%" }} />
            <Skeleton animated style={{ height: "20px", width: "40%" }} />
            <Skeleton animated style={{ height: "10px", width: "80%" }} />
          </article>
        ) : (
          <article id="progress">
            {states?.walletView && !isInPsView && (
              <Card
                title="Detalle del Servicio"
                type="inner"
                bordered
                className="ant-card-wallet"
              >
                <div className="pay-card-container">
                  <div className="pay-card-inside">
                    <span>
                      <label>Valor del servicio: </label>
                      <p>
                        {moneyFormat({
                          value: totalValue,
                        })}
                      </p>
                    </span>
                    <Popover
                      content="Abonar al pago"
                      placement="top-end"
                      mode="dark"
                      trigger="click"
                      visible={states.popupVisibility.pay}
                    >
                      <Avatar
                        onClick={() => {
                          actions.setOpenPayModal(true);
                          actions.setPopupVisibility({
                            pay: false,
                            wallet: false,
                          });
                        }}
                        style={{
                          "--size": "30px",
                          cursor: " pointer",
                          alignSelf: "flex-start",
                        }}
                        src={PAY_ICON}
                        alt="Icono de pago"
                      />
                    </Popover>
                  </div>
                  <div className="pay-card-inside-2">
                    <aside>
                      <label>Pagado: </label>
                      <p>
                        {moneyFormat({
                          value: data[serviceIndex].payment.paymentInfo.payed,
                        })}
                      </p>
                      <Progress
                        percent={(
                          data[serviceIndex].payment.paymentInfo.percent_payed *
                          100
                        ).toFixed(0)}
                        size="small"
                        strokeColor="#52c41a"
                        style={{ fontSize: "10px" }}
                      />
                    </aside>
                    <aside>
                      <label>Restante: </label>
                      <p>
                        {moneyFormat({
                          value: valueToPay,
                        })}
                      </p>
                      <Progress
                        percent={((valueToPay * 100) / totalValue).toFixed(0)}
                        size="small"
                        strokeColor="#f5222d"
                        style={{ fontSize: "10px" }}
                        status="exception"
                      />
                    </aside>
                  </div>
                </div>
              </Card>
            )}
            <div>
              <div className="progress-icons-header">
                <div style={{ display: "flex", gap: "10px" }}>
                  <Avatar
                    style={{
                      "--size": "30px",
                    }}
                    src={PROGRESSION_ICON}
                  />
                  <Popover
                    content="¿tienes apartamento?"
                    placement="top-end"
                    mode="ligth"
                    trigger="click"
                    visible={
                      states?.popupVisibility.wallet &&
                      data &&
                      !service?.metadata?.property?.has_property
                    }
                  >
                    <Space wrap>
                      <Switch
                        onChange={() => handleMarkAsProperty(service)}
                        checked={service?.metadata?.property?.has_property}
                        style={{
                          "--checked-color": "#00b578",
                          "--unchecked-color": "#f5222d",
                        }}
                        checkedText={<HouseIcon width={20} fill="white" />}
                        uncheckedText={<HouseIcon width={20} />}
                      />
                    </Space>
                  </Popover>
                </div>
                {!isInPsView && (
                  <Popover
                    content="Ver detalle de pago"
                    placement="top-end"
                    mode="ligth"
                    trigger="click"
                    visible={states?.popupVisibility.wallet && data}
                  >
                    <Badge
                      content={
                        data && data[serviceIndex].wallet.is_to_paid
                          ? Badge.dot
                          : null
                      }
                    >
                      {data && (
                        <Avatar
                          onClick={() => {
                            actions.setWalletView(!states.walletView);
                            actions.setPopupVisibility({
                              pay: !states.popupVisibility.pay ? true : false,
                              wallet: false,
                            });
                          }}
                          style={{ "--size": "30px", cursor: "pointer" }}
                          src={WALLET_ICON}
                        />
                      )}
                    </Badge>
                  </Popover>
                )}
              </div>
              <span className="progress-title-span">
                <h2>Mi progreso</h2>
                <p>{totalPercent.toFixed(0) || 0}%</p>
              </span>
            </div>
            <div className="progress-container">
              <div className="progress-bar-container">
                {service?.state?.globalState && (
                  <ProgressBar phases={phases} total={totalPercent} />
                )}
              </div>
              <p>{totalPercent.toFixed(0) || 0}%</p>
            </div>
          </article>
        )}

        {auxiliarPhasesArray.map((phase, index) => {
          const matchedPhase = phasesOptions?.find((option) => {
            const phaseCode = Number(option?.phase?.code);

            if (isInPsView && phaseCode === 3) {
              return false;
            }

            return phaseCode === phase.id;
          });

          if (loading) {
            return (
              <article key={index}>
                <Skeleton
                  className="skeleton-avatar"
                  animated
                  style={{ "--size": "110px", alignSelf: "flex-end" }}
                />
                <Skeleton.Paragraph lineCount={1} animated />
              </article>
            );
          }

          if (!matchedPhase) return null;

          const { style_id, name, id } = phase;
          const icon = PHASE_ICONS[style_id];

          const isActive = isPhaseActive(phase, service.state.stage);
          const isVisible = visiblePhases(service.state.stage).includes(id);

          return (
            <>
              {isVisible && (
                <article
                  onClick={() => setChat(phase, id)}
                  id={style_id}
                  key={index}
                >
                  {!isActive && phase.id != 3 && (
                    <span style={{ fontSize: "24px", color: "green" }}>
                      <CheckCircleOutlined />
                    </span>
                  )}
                  <Avatar className="avatar-phase" src={icon} />
                  <h2>{name}</h2>
                </article>
              )}
            </>
          );
        })}

        {isInPsView && haveServiceOperation && (
          <article
            onClick={() => {
              if (haveServiceOperation) {
                history.push(`/new-advance-detail/${service.unique_key}`);
              } else {
                history.push(`/services/entry/${service.unique_key}`);
              }
            }}
            id="work-plan"
          >
            <Avatar className="avatar-phase" src={WORKPLAN_ICON} />
            <h2>
              {haveServiceOperation ? "Plan de trabajo" : "Crear entrada"}
            </h2>
          </article>
        )}
        {isInPsView && !haveServiceOperation && !loading && (
          <FloatButton.Group
            trigger="click"
            type="primary"
            style={{
              insetInlineEnd: 24,
              insetBlockEnd: 100,
            }}
            icon={<AddOutline />}
          >
            <FloatButton
              onClick={() =>
                history.push(`/services/entry/${service.unique_key}`)
              }
              icon={<FileOutline />}
            />
            <FloatButton
              onClick={() =>
                //history.push(`/services/newUnexpected/${service.unique_key}`)
                openSimpleUnexpectedForm()
              }
              icon={<ExclamationTriangleOutline color="#fd992f" />}
            />
          </FloatButton.Group>
        )}
        <Drawer
          open={unExpectedFormActive}
          onClose={() => setUnExpectedFormActive(false)}
          title="Nuevo Imprevisto"
          placement="left"
          width="100vw"
          styles={{ color: "white" }}
        >
          <SimpleUnexpectedForm
            serviceId={service.unique_key}
            closeForm={() => setUnExpectedFormActive(false)}
          />
        </Drawer>
      </section>
      {isInPsView ? (
        <NavBarController />
      ) : (
        <Navigation userType={form_user?.register_type} history={history} />
      )}
    </main>
  ) : (
    <Services
      navigatorService={navigatorService}
      servicesList={servicesList}
      userType={form_user?.register_type}
      history={history}
    />
  );
};

export default NewHome;
