import { AlertOutlined, ClearOutlined, CustomerServiceOutlined, ProductOutlined, ShoppingOutlined, TruckOutlined } from "@ant-design/icons";
import { ReactComponent as DoorIcon } from "../../assets/icons/svg/door.svg";
import { ReactComponent as CountertopsIcon } from "../../assets/icons/svg/countertops.svg";

export function returnIconFromType(type) {
  switch (type) {
    case "carpentry":
      return <DoorIcon />;
    case "mesons":
      return <CountertopsIcon />;
    case "order_3":
      return <ShoppingOutlined />
    case "order_2":
      return <ShoppingOutlined />
    case "clean":
      return <ClearOutlined />;
    case "post_sales":
      return <CustomerServiceOutlined />;
    case "additional_order":
      return <ProductOutlined />;
    case "debris":
      return <TruckOutlined />;
    default:
      break;
  }
}  
  
export function returnNameFromType(type) {
  switch (type) {
    case "carpentry":
      return "Medidas de carpintería";
    case "mesons":
      return "Medidas de mesónes";
    case "order_3":
      return "Pedido No. 3";
    case "order_2":
      return "Pedido No. 2";
    case "clean":
      return "Servicio de aseo";
    case "post_sales":
      return "Postventa";
    case "additional_order":
      return "Pedido adicional";
    case "debris":
      return "Retiro de escombros";
    default:
      return "Desconocido";
  }
}