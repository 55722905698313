/*eslint-disable*/
import { MoreOutlined } from "@ant-design/icons";
import { Button, Dropdown, Image, Watermark, message } from "antd";
import React from "react";
import "../../views/newBitacle/components/chat/components/chatBody/ChatBody.css";
import PdfPreview from "components/atoms/pdfPreview/PdfPreview";
import "./ApprovalDocument.css";
import { Modal } from "antd-mobile";
import { useState } from "react";
import { handleApprovalDocument } from "services-controller/http.cleinte.controller";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import PdfPreviewCard from "./PdfPreviewCard";

const ApprovalDocument = ({
  items,
  messageObj,
  isUser,
  user,
  setCountApproved,
}) => {  
  const { id } = useParams();
  const [messageApi, contextHolder] = message.useMessage();
  const {
    detail,
    files = [],
    images = [],
    document_status = null,
    is_contract,
  } = messageObj;
  const approvalSell =
    "https://firebasestorage.googleapis.com/v0/b/justo-pago.appspot.com/o/evidence_resources%2F71e768bb-5708-581e-864d-0f0904722bfd.png?alt=media&token=3d318cbf-87e9-4ebe-a1fc-60cd7e41d13c";
  const rejectSell =
    "https://firebasestorage.googleapis.com/v0/b/justo-pago.appspot.com/o/evidence_resources%2Ffa650cc8-dfd4-52a9-b36e-f3211e837059.png?alt=media&token=9a6a5ef6-3222-4b1a-98f6-56ba201e4851";

  const [visible, setVisible] = useState(false);
  const [actionType, setActionType] = useState(null);

  const handleApprove = () => {
    setActionType("approve");
    setVisible(true);
  };

  const handleReject = () => {
    setActionType("reject");
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
    setActionType(null);
  };

  const handleConfirm = async () => {
    try {
      const status = actionType === "approve" ? "approved" : "rejected";
      const successMessage = actionType === "approve" ? "Documento aprobado" : "Documento rechazado";
      
      await handleApprovalDocument(id, messageObj, status);
      messageApi.success(successMessage);
      
      setCountApproved((prev) => prev + 1);
    } catch (error) {
      messageApi.error("Error al procesar la solicitud");
    } finally {
      setVisible(false);
      setActionType(null);
    }
  };

  const renderFiles = () => {
    return files?.map((file, index) => {
      const { url, name } = file;
      return <PdfPreviewCard key={index} title={name || "Otro sí"} pdfUrl={url} /> //<PdfPreview key={index} title={name || "Otro si"} pdfUrl={url} />;
    });
  };

  const renderImages = () => {
    return images.map((image, index) => (
      <Image
        key={index}
        src={image}
        width="auto"
        height="auto"
        onClick={(event) => event.stopPropagation()}
      />
    ));
  };

  return (
    <>
      {contextHolder}
      <Modal
        visible={visible}
        onClose={() => handleCancel()}
        title={`¿Desea ${
          actionType === "approve" ? "aprobar" : "rechazar"
        } este documento?`}
        content={`Al ${
          actionType === "approve"
            ? "aprobar este documento, usted autoriza a Justo Pago para continuar con el proceso."
            : "rechazar usted indica que no esta de acuerdo con el documento."
        } `}
        actions={[
          {
            text: actionType === "approve" ? "Aprobar" : "Rechazar",
            onClick: handleConfirm,
            primary: true,
            style: { backgroundColor: "#652d52" },
          },
          {
            text: "Cancelar",
            onClick: handleCancel,
            style: { color: "#652d52" },
          },
        ]}
      />
      <section className="approval-document-content">
        <Watermark
          height={280}
          width={330}
          offset={[130, 75]}
          image={
            document_status
              ? document_status === "approved"
                ? approvalSell
                : rejectSell
              : null
          }
        >
          <section className="message-wrapper">
            {user?.register_type != 100 && (
              <Dropdown
                menu={{ items: items.MessageDropdownItems }}
                placement="topRight"
                className="message-options-dropdown"
              >
                <MoreOutlined />
              </Dropdown>
            )}

            <div className="approval-document-content">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 10,
                }}
              >
                <p
                  className="message-paragraph"
                  dangerouslySetInnerHTML={{ __html: detail }}
                ></p>
                {files.length ? renderFiles() : renderImages()}
                {isUser && !document_status && !is_contract && (
                  <div className="approval-buttons">
                    <Button type="primary" onClick={handleApprove}>
                      Aprobar
                    </Button>
                    <Button type="default" onClick={handleReject}>
                      Rechazar
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </section>
        </Watermark>
      </section>
    </>
  );
};

export default ApprovalDocument;
