import React, { useState, useRef, useEffect, useCallback } from "react";
import RequestPanel from "./RequestPanel";
import { Form, Image, message } from "antd";
import {
  createNewServiceRequest,
  getAllMaterials,
} from "services-controller/http.cleinte.controller";
import moment from "moment";
import ConfirmModal from "../Modal/ConfirmModal";
import { UID_AUTH } from "../../../../persistent-managment/key.persistent";
import { getValueByKey } from "../../../../persistent-managment/managment.persistent";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { post } from "../../../../services/handler.request";
import { useSelector } from "react-redux";

const RequestController = ({ type, open, onClose }) => {
  const serviceId = useParams().id;
  const { servicesList } = useSelector((state) => state.services_list);
  const [form] = Form.useForm();
  const anchorRef = useRef(null);

  const [materials, setMaterials] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: [],
    parsedData: [],
  });
  const [images, setImages] = useState([]);
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] =
    useState(false);
  const [resultModal, setResultModal] = useState({
    visible: false,
    status: "",
    title: "",
  });
  const [dataToSend, setDataToSend] = useState(null);

  useEffect(() => {
    if (open) {
      anchorRef?.current?.setHeight(window.innerHeight * 0.6);
    }
  }, [open]);

  const labelRenderer = useCallback(
    (type, data) =>
      type === "month"
        ? moment()
            .month(data - 1)
            .format("MMMM")
        : data,
    []
  );

  const onAddFiles = (newFiles) => {
    setImages((prevImages) => [...prevImages, ...newFiles]);
  };

  const deleteMediaSource = (index) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const onAdd = () => {
    const materialList = form.getFieldValue("material_list") || [];
    materialList.push({ material: null, quantity: null });
    form.setFieldsValue({ material_list: materialList });
  };

  const onHeightChange = (height) => {
    if (height === 100) {
      onClose();
    }
  };

  const getMaterials = async () => {
    setMaterials((prev) => ({ ...prev, isLoading: true }));
    try {
      const res = await getAllMaterials();
      const selectOptions = res.map((material) => {
        const materialImage =
          material.images?.at(0)?.url ??
          material.options?.at(0).images?.at(0)?.url;
        return {
          label: (
            <div className="select-option-wrapper">
              {materialImage && (
                <Image
                  src={materialImage}
                  alt={material.name}
                  className="material-image"
                  width={52}
                  height={52}
                  preview={false}
                />
              )}
              <span className="select-option-name">{material.name}</span>
            </div>
          ),
          value: material.id,
          text: material.name,
          image: materialImage,
        };
      });
      setMaterials({
        isLoading: false,
        isSuccess: true,
        data: res,
        parsedData: selectOptions,
      });
    } catch {
      setMaterials((prev) => ({ ...prev, isError: true, isLoading: false }));
      message.error("Hubo un error al recuperar los materiales");
    }
  };

  const onSubmit = (values) => {
    if (
      images.length === 0 &&
      type.fields.some((field) => field.field_type === "picture")
    ) {
      message.error("Por favor sube al menos una imagen.");
      return;
    }
    setDataToSend({ ...values, files: images });
    setIsConfirmationModalVisible(true);
  };

  const buildRequestObject = (service_data, userId) => ({
    service_id: serviceId,
    type: type,
    dates: {
      due_date: dataToSend.date["$d"].toISOString(),
    },
    additional_info: dataToSend.additional_info ?? "",
    users: {
      user_creator: userId,
    },
    files: dataToSend.files,
    residential: dataToSend.residential ?? {
      address: "",
      neighborhood: "",
      city: "",
      phone: "",
      contact_name: "",
      contact_phone: "",
      coords: {
        lat: null,
        lng: null,
      },
    },
    metadata: {
      service_nickname: service_data.metadata.property.nickname ?? "",
      service_providers: service_data.service_providers ?? {},
    },
    material_list: dataToSend.material_list ?? [],
  });

  const handleAccept = async () => {
    try {
      const service_data = servicesList.find(
        (service) => service.unique_key === serviceId
      );

      const userId = getValueByKey(UID_AUTH);
      const requestObject = buildRequestObject(service_data, userId);
      setIsConfirmationModalVisible(false);

      await createNewServiceRequest(serviceId, requestObject);
      setResultModal({
        visible: true,
        status: "success",
        title: "Solicitud enviada",
      });
      setTimeout(() => {
        onClose();
      }, 2000);
    } catch (err) {
      const errorStatus = err.response?.status;
      const errorMessage = err.response?.data?.message;

      if (errorStatus === 409) {
        setResultModal({
          visible: true,
          status: "error",
          title: errorMessage || "Conflicto detectado",
        });
        setTimeout(() => {
          onClose();
        }, 2000);
      }
    }
  };

  const handleReject = async () => {
    setIsConfirmationModalVisible(false);
    setResultModal({
      visible: true,
      status: "error",
      title: "Solicitud cancelada",
    });
  };

  const renderField = (type, id) => {
    return type.fields.some((field) => field.field_type === id);
  };

  const actions = {
    onAddFiles,
    deleteMediaSource,
    getMaterials,
    onAdd,
    onSubmit,
    renderField,
    labelRenderer,
    onHeightChange,
  };

  const formProps = {
    form,
    materials,
    images,
  };

  const modalActions = {
    setIsConfirmationModalVisible,
    handleAccept,
    handleReject,
    setResultModal,
  };

  const modalStates = {
    isConfirmationModalVisible,
    resultModal,
  };

  return (
    <div className="request-controller-container">
      <div className="request-controller-backdrop" />
      <RequestPanel
        type={type}
        open={open}
        onClose={onClose}
        actions={actions}
        formProps={formProps}
        ref={anchorRef}
      />

      <ConfirmModal
        materials={materials}
        data={dataToSend}
        modalActions={modalActions}
        modalStates={modalStates}
      />
    </div>
  );
};

export default RequestController;
