import React from "react";
import "./PdfPreviewCard.css";

const PdfPreviewCard = ({ title, pdfUrl }) => {
  return (
    <a
      href={pdfUrl}
      target="_blank"
      rel="noopener noreferrer"
      className="pdf-card"
    >
      <div className="pdf-icon">📄</div>
      <div className="pdf-info">
        <h4 className="pdf-title">{title}</h4>
        <p className="pdf-subtext">Toca para abrir</p>
      </div>
    </a>
  );
};

export default PdfPreviewCard;
