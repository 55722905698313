import React, { forwardRef } from "react";
import { DeleteOutlined, PlusOutlined, SendOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Empty,
  Form,
  Input,
  InputNumber,
  Select,
} from "antd";
import { FloatingPanel } from "antd-mobile";
import InputFile from "components/InputFile/InputFile";
import { returnNameFromType } from "components/Navbar/utils";

const MaterialList = ({ id, materials, getMaterials, onAdd }) => (
  <>
    <Form.Item id={id} label="Materiales">
      <Form.List name="material_list">
        {(fields, { remove }) => (
          <section
            className="material-list"
            onPointerEnter={(event) => event.stopPropagation()}
          >
            {!fields.length && (
              <Empty description="No hay materiales adicionales" />
            )}

            {fields.map(({ key, name, ...restField }) => (
              <article className="material-list-item" key={key}>
                <Button
                  onClick={() => remove(name)}
                  danger
                  size="small"
                  type="primary"
                  icon={<DeleteOutlined />}
                  className="remove-item-btn"
                />
                <div className="material-item-wrapper">
                  <Form.Item
                    {...restField}
                    rules={[
                      {
                        required: true,
                        message: "Debes seleccionar el material",
                      },
                    ]}
                    name={[name, "material"]}
                    label="Material"
                  >
                    <Select
                      className="material-select"
                      onDropdownVisibleChange={(value) => {
                        if (value && !materials.data.length) {
                          getMaterials();
                        }
                      }}
                      options={materials.parsedData}
                      loading={materials.isLoading}
                      showSearch
                      optionFilterProp="text"
                      filterSort={(optionA, optionB) =>
                        (optionA?.text ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.text ?? "").toLowerCase())
                      }
                      allowClear
                    />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    rules={[
                      {
                        required: true,
                        message: "La cantidad del material es necesaria",
                      },
                    ]}
                    name={[name, "quantity"]}
                    label="Cantidad"
                  >
                    <InputNumber size="large" />
                  </Form.Item>
                </div>
              </article>
            ))}
          </section>
        )}
      </Form.List>
      <footer className="form-actions-section">
        <Button
          icon={<PlusOutlined />}
          className="to-add-button"
          type="dashed"
          onClick={onAdd}
          block
        >
          Agregar Material
        </Button>
      </footer>
    </Form.Item>
    <Form.Item
      rules={[
        {
          required: true,
          message: "Debe escribir la razon por la cual necesitas este material",
        },
      ]}
      name="additional_info"
      label="¿Porqué necesitas estos materiales?"
    >
      <Input.TextArea
        rows={4}
        placeholder="Escribe la razón aquí..."
        className="reason-textarea"
      />
    </Form.Item>
  </>
);

const RequestPanel = forwardRef(({ type, actions, formProps }, ref) => {
  const anchors = [100, window.innerHeight * 0.2, window.innerHeight * 0.6];
  const now = new Date();
  const max = new Date(now.getFullYear(), now.getMonth() + 4, now.getDate());

  return (
    <FloatingPanel
      anchors={anchors}
      ref={ref}
      onHeightChange={actions.onHeightChange}
    >
      <section className="section-request-controller">
        <header>
          <h2>{returnNameFromType(type.type)}</h2>
        </header>
        <Form
          onFinish={actions.onSubmit}
          layout="vertical"
          rootClassName="order-form"
          form={formProps.form}
        >
          {actions.renderField(type, "date") && (
            <Form.Item
              id="date"
              label="Fecha en la que se debe entregar el pedido"
              name="date"
              rules={[
                {
                  required: true,
                  message: "Por favor ingresa información adicional",
                },
              ]}
            >
              <DatePicker
                renderLabel={actions.labelRenderer}
                min={now}
                max={max}
                cancelText="Cancelar"
                confirmText="Guardar"
                title="Selecciona la fecha de entrega"
              />
            </Form.Item>
          )}

          {actions.renderField(type, "info_field") && (
            <Form.Item
              id="info_field"
              label="Información adicional"
              name="additional_info"
              rules={[
                {
                  required: true,
                  message: "Por favor ingresa información adicional",
                },
              ]}
            >
              <Input.TextArea rows={4} placeholder="Escribe aquí..." />
            </Form.Item>
          )}

          {actions.renderField(type, "picture") && (
            <Form.Item id="picture" label="Subir imágenes">
              <InputFile
                images={formProps.images}
                deleteMediaSource={actions.deleteMediaSource}
                onAddFiles={actions.onAddFiles}
                limit={5}
                filesAccept={["image/*"]}
                disabled={false}
              />
            </Form.Item>
          )}

          {actions.renderField(type, "materials") && (
            <MaterialList
              id="materials"
              materials={formProps.materials}
              getMaterials={actions.getMaterials}
              onAdd={actions.onAdd}
            />
          )}

          <Button
            type="primary"
            htmlType="submit"
            icon={<SendOutlined />}
            size="medium"
            className="order-submit-btn"
            block
          >
            Realizar pedido
          </Button>
        </Form>
      </section>
    </FloatingPanel>
  );
});

export default RequestPanel;

/**
 
          <Form.Item label="Asignar a:">
            <div
              style={{
                display: "flex",
                gap: 8,
                justifyContent: "space-between",
              }}
            >
              <Avatar icon={<UserOutlined />} />
              <Avatar.Group max={{ count: 6, popover: true }}>
                <SearchBar
                  placeholder="请输入内容"
                  showCancelButton={() => true}
                />
                {formProps.allWorkers.map((worker) => {
                  return (
                    <Avatar
                      src={worker.thumbnail}
                      icon={worker.thumbnail === "" ? <UserOutlined /> : null}
                      alt={worker.name}
                      key={worker.id}
                    />
                  );
                })}
              </Avatar.Group>
            </div>
          </Form.Item>
 */
