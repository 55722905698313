import { Button, Modal } from "antd";
import React, { useState, useEffect } from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
//import NewUnexpectedForm from "views/newUnexpectedForm/newUnexpectedForm";
import NavBar from "./NavBar";
import { getAllRequestTypes } from "services-controller/http.cleinte.controller";
import { returnIconFromType, returnNameFromType } from "./utils";
import RequestController from "./components/Request/RequestController";

const NavBarController = ({ isInWorkerPlan = false }) => {
  const { id } = useParams();
  const history = useHistory();
  const [isAddNewModalOpen, setIsAddNewModalOpen] = useState(false);
  //const [isNotificationFormOpen, setIsUnexpectedModalOpen] = useState(false);
  const [showRequestForm, setShowRequestForm] = useState(false);
  const [selectedRequestType, setSelectedRequestType] = useState(null);
  const [types, setTypes] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [fetchedTypes] = await Promise.all([getAllRequestTypes()]);

        setTypes(fetchedTypes);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleOpenRequestForm = (type) => {
    const newType = types.find((t) => t.type === type);
    if (newType) {
      setSelectedRequestType(newType);
      setShowRequestForm(true);
    }
  };

  const onCloseRequestForm = () => {
    setShowRequestForm(false);
    setSelectedRequestType(null);
  };

  const openModal = isAddNewModalOpen && !showRequestForm;
  //!isNotificationFormOpen;

  return (
    <>
      <NavBar
        history={history}
        isInWorkerPlan={isInWorkerPlan}
        id={id}
        handleOpenNewOptions={setIsAddNewModalOpen}
      />

      <Modal
        centered
        closable
        maskClosable
        footer={null}
        title={null}
        open={openModal}
        onCancel={() => setIsAddNewModalOpen(false)}
        rootClassName="options-modal"
      >
        <section className="add-new-option">
          {types?.map((type) => (
            <Button
              onClick={() => handleOpenRequestForm(type.type)}
              key={type.id}
              icon={returnIconFromType(type.type)}
            >
              {returnNameFromType(type.type)}
            </Button>
          ))}
        </section>
      </Modal>

      {/* <Drawer
        open={isNotificationFormOpen}
        title="Nuevo Imprevisto"
        placement="left"
        width="100vw"
        onClose={() => setIsUnexpectedModalOpen(false)}
        styles={{ color: "white" }}
      >
        <NewUnexpectedForm onClose={() => setIsUnexpectedModalOpen(false)} />
      </Drawer> */}

      {showRequestForm && selectedRequestType && (
        <RequestController
          type={selectedRequestType}
          open={showRequestForm}
          onClose={onCloseRequestForm}
        />
      )}
    </>
  );
};

export default NavBarController;

/**
  const selectOptions = serviceProviders.map((provider) => ({
    name: provider.register_data.name,
    id: provider.id,
    role: provider.role,
    register_type: provider.register_type,
    user_type: provider.user_type,
    thumbnail: provider.register_data.thumbnail,
  }));

  // Filter out workers with a role
  const workersWithRole = selectOptions.filter((worker) => worker.role);
 */
