import React, { useEffect, useState } from "react";
import NewHome from "./NewHome";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import useReduxState from "custom-hooks/useReduxState";
import { setChatPhaseSelected, setPhases } from "reducers/bitacle.reducer";
import {
  getAllEntriesByService,
  getPendingPaymentAmount,
  getServiceWalletById,
} from "services-controller/http.cleinte.controller";
import {
  changeWalletViewHeight,
  constructorList,
  updateList,
} from "../services/services";
import {
  getAllServicesInit,
  getAllServicesInProgress,
} from "services-controller/database";
import { updateServicesList } from "reducers/servicesList.reducer";
import PayModalController from "views/wallet/modal/PayModalController";
import { DETAILS, PHASES } from "router/routes";

const NewHomeController = ({ history }) => {
  const dispatch = useDispatch();

  const [servicesListInit, setServicesListInit] = useState([]);
  const [servicesListInProgress, setServicesListInProgress] = useState([]);

  const form_user = useSelector((state) => state.form_user);
  const [initialService, setInitialService] = React.useState(null);
  const [loading, setLoading] = useState(true);
  const [walletView, setWalletView] = useState(false);
  const [data, setData] = useState(null);
  const [openPayModal, setOpenPayModal] = useState(false);
  const [openPayRequiredModal, setOpenPayRequiredModal] = useState(false);
  const [popupVisibility, setPopupVisibility] = useState({
    pay: false,
    wallet: true,
  });

  const { servicesList } = useSelector((state) => state.services_list);
  const phasesOptions = useSelector((state) => state.bitacle.phases);

  const [, dispatcher] = useReduxState(
    { setChatPhaseSelected, setPhases },
    "bitacle"
  );

  useEffect(() => {
    changeWalletViewHeight(walletView);
  }, [walletView]);

  useEffect(() => {
    const servicesListUpdated = constructorList(
      servicesListInit,
      servicesListInProgress
    );

    dispatch(updateServicesList(servicesListUpdated));
  }, [servicesListInit, servicesListInProgress]); //eslint-disable-line

  useEffect(() => {
    const listenerServicesInit = getAllServicesInit((snapshot) =>
      updateList(snapshot, setServicesListInit)
    );

    const listenerServicesProgress = getAllServicesInProgress((snapshot) =>
      updateList(snapshot, (rawList) => {
        const inProgressOnly = rawList.filter(
          (service) => service.state.stage !== 13 && service.state.stage !== 11
        );
        setServicesListInProgress(inProgressOnly);
      })
    );

    return () => {
      listenerServicesInit();
      listenerServicesProgress();
    };
  }, []);

  useEffect(() => {
    if (
      servicesList &&
      servicesList.length > 0 &&
      servicesList[0]?.length > 1
    ) {
      setInitialService(servicesList[0][1]?.[0]);
    } else {
      setInitialService(null);
    }
  }, [servicesList]);

  useEffect(() => {
    const getPhases = async () => {
      if (initialService?.unique_key) {
        setLoading(true); // Inicia la carga
        const response = await getAllEntriesByService(
          initialService?.unique_key
        );
        dispatcher.setPhases(response?.bitacle[0]?.entrys);
        setLoading(false);
      }
    };

    getPhases();
  }, [initialService]); //eslint-disable-line

  useEffect(() => {
    const fetchData = async () => {
      const results = await Promise.all(
        servicesListInProgress.flatMap(async (service) => {
          const res = await hasServiceDoubts(service.unique_key);
          return res; // Retorna el resultado de cada servicio
        })
      );

      setData(results);
    };

    if (servicesListInProgress.length > 0) {
      fetchData();
    }
  }, [servicesListInProgress]);

  const hasServiceDoubts = async (service) => {
    const wallet = await getServiceWalletById(service);
    const payment = await getPendingPaymentAmount(service);

    return {
      wallet,
      payment,
    };
  };

  function setChat(phase, index) {
    const name = phase.name;
    dispatcher.setChatPhaseSelected({ key: index, name });
    const route = `/chat/${initialService.unique_key}/fase/${index}`;
    history.push(route);
  }

  const handleChangeService = (service) => {
    setInitialService(service);
  };

  const navigatorService = async (id, stage, version) => {
    const { bitacle } = await getAllEntriesByService(id);

    //legacy entry unique_key is unique string and new entry unique_key is an array index
    const someLegacy = bitacle[0].entrys?.some(
      (entry) => entry.unique_key?.length > 1
    );
    if (bitacle[0].entrys.length > 1 && !someLegacy) {
      version = 2;
    } else {
      version = 1;
    }
    if (version === 2) {
      history.push(`${PHASES}/${id}`);
    } else {
      history.push(`${DETAILS}/${id}/${stage}`);
    }
  };

  let actions = {
    setWalletView,
    setOpenPayRequiredModal,
    setOpenPayModal,
    setPopupVisibility,
  };

  let states = {
    walletView,
    openPayRequiredModal,
    openPayModal,
    popupVisibility,
  };

  return (
    <>
      <NewHome
        servicesInCourse={servicesList?.at(0)?.at(1)}
        service={initialService}
        form_user={form_user}
        history={history}
        handleChangeService={handleChangeService}
        phasesOptions={phasesOptions}
        setChat={setChat}
        loading={loading}
        actions={actions}
        states={states}
        data={data}
        servicesList={servicesList}
        navigatorService={navigatorService}
      />

      <PayModalController
        data={data}
        states={states}
        actions={actions}
        selectedProject={initialService}
      />
    </>
  );
};

export default withRouter(NewHomeController);
